import { Container, Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MobileSheet from 'src/components/MobileSheet';
import PageWrapper from 'src/components/PageWrapper';
import useLocalStorage from 'src/hooks/useLocalStorage';
import safeCallback from 'src/utils/safeCallback';

import OnboardingActions from './OnboardingActions';
import OnboardingContent from './OnboardingContent';
import OnboardingIndicator from './OnboardingIndicator';

function Onboarding({
  disableClickIndicator,
  endActionIcon,
  endActionLabel,
  forceHideOnboarding,
  forceShowOnboarding,
  fullHeight,
  name,
  notSkipable,
  onAfterEnd,
  onEnd,
  steps,
  withIndicators,
}) {
  const onboardingName = `onboarding-${name}-visible`;
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOnboarding, setShowOnboarding] = useLocalStorage(onboardingName, true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [onClick, setOnClick] = useState(null);

  const onboardingLength = steps.length;

  const handleNextStep = useCallback(async () => {
    const handleOnClick = onClick || steps[activeStep]?.onClick;
    setLoading(true);
    try {
      if (handleOnClick) await handleOnClick();
      setOnClick(null);
    } catch {
      setLoading(false);
      return;
    }

    setLoading(false);
    setNextDisabled(false);

    setActiveStep((oldActiveStep) => {
      if (oldActiveStep < onboardingLength - 1) return oldActiveStep + 1;

      return oldActiveStep;
    });
  }, [activeStep, onClick, onboardingLength, steps]);

  const handlePreviousStep = useCallback(() => {
    setOnClick(null);
    setActiveStep((oldActiveStep) => {
      if (oldActiveStep > 0) return oldActiveStep - 1;

      return oldActiveStep;
    });
  }, []);

  const handleClose = useCallback(async () => {
    setLoading(true);
    try {
      await safeCallback(steps[activeStep]?.onClick);
      await safeCallback(onEnd);
    } catch {
      // Nothing to do for now
    }
    setLoading(false);
    setShowOnboarding(false);

    await safeCallback(onAfterEnd);
  }, [activeStep, onEnd, setShowOnboarding, steps, onAfterEnd]);

  const handleGoToStep = useCallback(
    async (stepIndex) => {
      setLoading(true);
      if (stepIndex > activeStep) {
        try {
          await safeCallback(steps[activeStep]?.onClick);
        } catch {
          // Nothing to do for now
        }
      }
      setLoading(false);
      setActiveStep(stepIndex);
    },
    [activeStep, steps]
  );

  useEffect(() => {
    const setOnboardingStatus = () => {
      if (forceHideOnboarding && !showOnboarding) {
        setShowOnboarding(false);
        return;
      }
      if (forceShowOnboarding) setShowOnboarding(true);
    };

    setTimeout(setOnboardingStatus, 1_000);
  }, [forceHideOnboarding, forceShowOnboarding, setShowOnboarding, showOnboarding]);

  const handleReset = useCallback(() => setActiveStep(0), []);

  const currentStep = useMemo(() => steps[activeStep], [activeStep, steps]);

  const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);
  const isLastStep = useMemo(
    () => activeStep === onboardingLength - 1,
    [activeStep, onboardingLength]
  );

  const { children, content, imageSrc, subtitle, title, videoSrc } = currentStep || {};

  return (
    <MobileSheet fullHeight={fullHeight} notSwipeable open={showOnboarding} withoutPullToRefresh>
      <Container disableGutters maxWidth="xs" sx={{ height: '100%' }}>
        {withIndicators && (
          <OnboardingIndicator
            activeStep={activeStep}
            count={onboardingLength}
            onIndicatorClick={!disableClickIndicator && handleGoToStep}
          />
        )}

        <Stack height="100%" justifyContent="space-between" spacing={2}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              initial={{ opacity: 0, scale: 1 }}
              style={{ display: 'flex', height: '100%' }}
              transition={{ duration: 0.5 }}
            >
              <Stack flex={1} justifyContent="space-between" spacing={2}>
                <PageWrapper subtitle={subtitle} title={title} withoutLogo />
                <OnboardingContent
                  content={content}
                  imageSrc={imageSrc}
                  setNextDisabled={setNextDisabled}
                  setOnClick={setOnClick}
                  videoSrc={videoSrc}
                >
                  {children}
                </OnboardingContent>
              </Stack>
            </motion.div>
          </AnimatePresence>

          <OnboardingActions
            endActionIcon={endActionIcon}
            endActionLabel={endActionLabel}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            loading={loading}
            nextDisabled={nextDisabled}
            notSkipable={notSkipable}
            onEnd={handleClose}
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            onReset={handleReset}
          />
        </Stack>
      </Container>
    </MobileSheet>
  );
}

Onboarding.defaultProps = {
  notSkipable: false,
};

export default Onboarding;

import { CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { PATH_USER } from 'src/routes/paths';

import getAvatarName from '../../utils/getAvatarName';
import Avatar from '../@extended/Avatar';

function UserAvatar({ children, loading, size, sx, user, withLink, ...props }) {
  const navigate = useNavigate();

  const avatar = user?.avatar;
  const firstname = user?.firstname;

  const handleClickAvatar = useCallback(() => {
    if (withLink) {
      navigate(PATH_USER.userProfile(user.id));
    }
  }, [navigate, user.id, withLink]);

  return (
    <Avatar
      color="primary"
      onClick={handleClickAvatar}
      size={size}
      src={!loading && avatar}
      type="outlined"
      {...props}
      sx={{
        ...(withLink && {
          cursor: 'pointer',
        }),
        ...(sx && sx),
      }}
    >
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <>
          {children && children}
          {!children && getAvatarName(firstname)}
        </>
      )}
    </Avatar>
  );
}

UserAvatar.defaultProps = {
  size: 'sm',
};

export default UserAvatar;

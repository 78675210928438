import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';

const BASE_TITLE = 'Coccinelle | ';
const DEFAULT_TITLE = 'Le covoiturage qui rapporte gros';
const DEFAULT_DESCRIPTION =
  'Gagne 40€ sur ton premier trajet de covoiturage avec Coccinelle grâce à la prime CEE.  Enfin un covoiturage qui rapporte dès la première course !';
const DEFAULT_KEYWORDS = [
  "gagner de l'argent",
  "gagner de l'argent facilement",
  'argent rapide',
  'revenus supplémentaires',
  'complément de revenu',
  "économiser de l'argent",
  'covoiturage rémunéré',
  'partage de trajet',
  'covoiturage court trajet',
  'covoiturage long trajet',
  'trajets partagés',
  'conducteurs de covoiturage',
  'passagers covoiturage',
  'prime CEE covoiturage',
  'prime Coup de Pouce',
  'prime trajets courts',
  'prime trajets longs',
  'bonus écologique covoiturage',
  'subvention covoiturage',
  'aide financière covoiturage',
  'application covoiturage',
  'appli covoiturage',
  'service de covoiturage',
  'plateforme de covoiturage',
  'faciliter covoiturage',
  'organisation de trajets',
  'partager ses trajets',
  'réduire les frais de transport',
  'mobilité durable',
  'trajets éco-responsables',
  'simplifier les déplacements',
  'covoiturage pratique',
  'covoiturage sans stress',
  'covoiturage en ville',
  'covoiturage rural',
  'covoiturage local',
  'covoiturage interurbain',
];

const SEOContext = createContext();

const useSEOContext = () => useContext(SEOContext);

function SEOProvider({ children }) {
  const DEFAULT_BASE_TITLE = `${BASE_TITLE}${DEFAULT_TITLE}`;

  const [title, setTitle] = useState(DEFAULT_BASE_TITLE);
  const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
  const [keywords, setKeywords] = useState(DEFAULT_KEYWORDS);

  const setCustomTitle = useCallback(
    (customTitle, withoutBaseTitle) =>
      setTitle(withoutBaseTitle ? customTitle : `${BASE_TITLE}${customTitle || DEFAULT_TITLE}`),
    []
  );

  const memoizedContextValue = useMemo(
    () => ({
      setDescription,
      setKeywords,
      setTitle: setCustomTitle,
    }),
    [setCustomTitle]
  );

  return (
    <SEOContext.Provider value={memoizedContextValue}>
      <Helmet>
        <title>{title || DEFAULT_BASE_TITLE}</title>
        <meta content={description || DEFAULT_DESCRIPTION} name="description" />
        <meta content={(keywords || DEFAULT_KEYWORDS).join(',')} name="keywords" />
      </Helmet>
      {children}
    </SEOContext.Provider>
  );
}

export { SEOProvider, useSEOContext };

import {
  Box,
  Drawer,
  Stack,
  SwipeableDrawer,
  alpha,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MAIN_ID } from 'src/config';
import safeCallback from 'src/utils/safeCallback';

import PullToRefresh from './pull-to-refresh';

function Grabber({ notSwipeable, target }) {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: target || window,
    threshold: 12,
  });

  if (!target) return null;

  const transformDegree = trigger ? 10 : 0;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={-0.25}
      sx={{
        backgroundColor: 'background.default',
        borderTopLeftRadius: `${theme.shape.borderRadius * 2}px`,
        borderTopRightRadius: `${theme.shape.borderRadius * 2}px`,
        boxShadow: trigger && theme.shadows[6],
        left: 0,
        minHeight: 32,
        position: 'sticky',
        top: -1,
        transition: 'all 0.5s ease',
        zIndex: theme.zIndex.drawer - 1,
        ...(notSwipeable && {
          backgroundColor: 'transparent',
          backgroundImage: `linear-gradient(to top, rgba(255,255,255,0), ${theme.palette.background.default} 70%)`,
          boxShadow: 'none',
        }),
      }}
    >
      {!notSwipeable && (
        <>
          <Box
            sx={{
              backgroundColor: 'grey.700',
              borderRadius: 32,
              height: 3,
              transform: `rotate(${transformDegree}deg)`,
              transition: 'all 0.5s ease',
              width: 16,
            }}
          />
          <Box
            sx={{
              backgroundColor: 'grey.700',
              borderRadius: 32,
              height: 3,
              transform: `rotate(-${transformDegree}deg)`,
              transition: 'all 0.5s ease',
              width: 16,
            }}
          />
        </>
      )}
    </Stack>
  );
}

function MobileSheet({
  children,
  fullHeight,
  height,
  notSwipeable,
  onCloseEnd,
  open,
  sx,
  variant,
  withoutPullToRefresh,
  ...props
}) {
  const [contentVisible, setContentVisible] = useState(false);
  const theme = useTheme();
  const sheetRef = useRef();
  const paperSheetRef = useRef();

  const isFullHeight = height === '100%' || fullHeight;
  const finalHeight = fullHeight ? '100%' : height;
  const isCard = variant === 'card';

  const handleEnd = useCallback(() => {
    const rootContainer = document.getElementById(MAIN_ID);
    let countDrawer = Number(rootContainer?.dataset?.drawerCount || 0);

    if (!open) {
      safeCallback(onCloseEnd);
      setTimeout(() => {
        setContentVisible(false);
      }, 500);
      countDrawer -= 1;
    } else {
      setContentVisible(true);
      countDrawer += 1;
    }

    if (rootContainer) {
      // Update dataset
      rootContainer.dataset.drawerCount = countDrawer;

      if (countDrawer > 0) {
        rootContainer.style.transform = 'scale(0.9)';
      } else {
        rootContainer.style.transform = 'none';
      }
    }
  }, [onCloseEnd, open]);

  useEffect(
    () => () => {
      const rootContainer = document.getElementById(MAIN_ID);

      if (rootContainer) {
        rootContainer.dataset.drawerCount = 0;
        rootContainer.style.transform = 'none';
      }
    },
    []
  );

  const DrawerComponent = notSwipeable ? Drawer : SwipeableDrawer;

  return (
    <DrawerComponent
      ref={sheetRef}
      anchor="bottom"
      disableSwipeToOpen
      onOpen={() => null}
      open={open}
      PaperProps={{
        ref: paperSheetRef,
        sx: {
          backgroundColor: theme.palette.background.default,
          overflowX: 'hidden',
          ...(!isFullHeight && {
            borderTopLeftRadius: `${theme.shape.borderRadius * 2}px`,
            borderTopRightRadius: `${theme.shape.borderRadius * 2}px`,
          }),
          ...(isFullHeight && {
            borderColor: `${theme.palette.background.default}!important`,
            borderWidth: `${0}px !important`,
          }),
          borderBottomWidth: `0px !important`,
          borderLeftWidth: `0px !important`,
          borderRightWidth: `0px !important`,
          borderTopWidth: `0px !important`,
          boxShadow: `0px 0px 10px 0px ${alpha(theme.palette.common.black, 0.5)}`,
          height: finalHeight,
          maxHeight: finalHeight || '100vh',
          overflowY: 'scroll',
          ...(sx && sx),
          ...(isCard && {
            borderRadius: `${theme.shape.borderRadius * 2}px`,
            margin: 2,
          }),
        },
      }}
      SlideProps={{
        addEndListener: handleEnd,
        appear: true,
      }}
      {...props}
    >
      {notSwipeable && !withoutPullToRefresh && <PullToRefresh target={sheetRef.current} />}
      <Grabber notSwipeable={notSwipeable} target={paperSheetRef.current} />
      <Box pt={4} />
      <Stack flexGrow={1} px={2}>
        {contentVisible && children}
      </Stack>
      <Box pb={4} />
    </DrawerComponent>
  );
}

MobileSheet.defaultProps = {
  fullHeight: false,
  height: 'calc(100vh - 64px)',
  notSwipeable: false,
  open: false,
  withoutPullToRefresh: false,
};

export default MobileSheet;

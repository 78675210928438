import React from 'react';
import Snackbar from 'src/components/@extended/Snackbar';

import ErrorBoundary from './components/error-boundary';
import NoInternetHandler from './components/NoInternetHandler';
import { PullToRefreshProvider } from './components/pull-to-refresh/PullToRefreshContext';
import ScrollTop from './components/ScrollTop';
import ThemeHandler from './components/ThemeHandler';
import Notistack from './components/third-party/Notistack';
import { AlertProvider } from './contexts/AlertContext';
import { AuthProvider } from './contexts/auth/AuthContext';
import { ConnectivityProvider } from './contexts/ConnectivityContext';
import { CurrentTripProvider } from './contexts/CurrentTripContext';
import { DialogsProvider } from './contexts/DialogsContext';
import { LocationProvider } from './contexts/LocationContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { SEOProvider } from './contexts/SEOContext';
import { LandingInformationProvider } from './contexts/useLandingInformation';
import { UserProvider } from './contexts/UserContext';
import ExternalLinksBinder from './messages/ExternalLinksBinder';
import NativeFeatures from './messages/NativeFeatures';
import GlobalRoutes from './routes';
import ThemeCustomization from './themes';

function App() {
  return (
    <ThemeCustomization>
      <ErrorBoundary>
        <Notistack>
          <PullToRefreshProvider>
            <ConnectivityProvider>
              <NoInternetHandler />
              <NativeFeatures />
              <DialogsProvider>
                <ScrollTop>
                  <LandingInformationProvider>
                    <AuthProvider>
                      <ThemeHandler>
                        <AlertProvider>
                          <UserProvider>
                            <LocationProvider>
                              <NotificationProvider>
                                <CurrentTripProvider>
                                  <SEOProvider>
                                    <GlobalRoutes />
                                    <Snackbar />
                                    <ExternalLinksBinder />
                                  </SEOProvider>
                                </CurrentTripProvider>
                              </NotificationProvider>
                            </LocationProvider>
                          </UserProvider>
                        </AlertProvider>
                      </ThemeHandler>
                    </AuthProvider>
                  </LandingInformationProvider>
                </ScrollTop>
              </DialogsProvider>
            </ConnectivityProvider>
          </PullToRefreshProvider>
        </Notistack>
      </ErrorBoundary>
    </ThemeCustomization>
  );
}

export default App;
